.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.menu li {
  flex: 1;
  transition: background-position-x 0.9s linear;
  text-align: center;
  padding: 15px;
}

.menu li.active a {
  color: #2245a9;
}

.menu li a {
  font-size: 22px;
  color: #777;
  text-decoration: none;
  transition: all 0.45s;
}