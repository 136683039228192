.height90 {
    height: 90% !important;
}

.back {
    margin-bottom: 50%;
}

.resend {
    display: flex;
    flex-direction: row;
    margin: 0 0 20px;
}

.resend-text {
    flex: 1;
}

.resend-button {
    color: #2245a9;
    font-weight: 600;
}

.mg10 {
    margin: 10px 0;
}