.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-red {
    color: #ff0000;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
}

.login-page {
    width: 50%;
}

.header-section {
    margin-bottom: 40px;
}

.txtText {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2245a9;
    text-align: center;
}

.txtTextDescription {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2245a9;
    text-align: center;
}

.form-group {
    margin-bottom: 1.2rem;
}

.form-group label {
    color: #858585;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
}

.form-group input {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 400;
    color: #858585;
    height: 45px;
    width: 100%;
}

.login {
    text-align: center;
}

.login a {
    margin-top: 1rem;
    font-size: 1rem;
    color: #2245a9;
    font-weight: 600;
}

.login a:hover {
    color: #2245a9;
}

.btn-login {
    width: 35%;
    height: 45px;
    margin-top: 30px;
    color: #fff !important;
    background-color: #2245a9 !important;
    border-radius: 30px;
}

.btn-disabled {
    background-color: #e5e5e5 !important;
    color: #ffffff !important;
}

.languageSection {
    font-size: 25px;
    width: 50%;
    display: block;
    margin: 0 auto;
    text-align: right;
    padding: 20px 15px;
}

@media screen and (max-width: 768px) {
    .login-page {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    .btn-login {
        width: 70%;
    }

    .languageSection {
        width: 100% !important;
    }
}